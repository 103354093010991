export const CREATE_CATEGORY_SUCCEEDED_MESSAGE = {
  title: 'New report category created',
  description: 'You have successfully created the new report category',
};
export const CREATE_CATEGORY_FAILED_MESSAGE = {
  title: 'New report category',
  description: 'Create new report category failed!',
};
export const UPDATE_CATEGORY_SUCCEEDED_MESSAGE = {
  title: 'Report category updated',
  description: 'You have successfully update report category',
};
export const UPDATE_CATEGORY_FAILED_MESSAGE = {
  title: 'Report category updated',
  description: 'Your changes have been failed!',
};
export const DELETE_CATEGORY_SUCCEEDED_MESSAGE = {
  title: 'Delete category',
  description: 'You have successfully deleted the category',
};
export const DELETE_CATEGORY_FAILED_MESSAGE = {
  title: 'Delete category',
  description: 'Delete category failed!',
};
export const CREATE_CATEGORY_TYPE_SUCCEEDED_MESSAGE = {
  title: 'New category type created',
  description: 'You have successfully created the new category type',
};
export const CREATE_CATEGORY_TYPE_FAILED_MESSAGE = {
  title: 'New category type',
  description: 'Create new category type failed!',
};
export const UPDATE_PINNED_CATEGORIES_SUCCEEDED_MESSAGE = {
  title: '',
  description: 'Your changes have been successfully saved!',
};
export const UPDATE_PINNED_CATEGORIES_FAILED_MESSAGE = {
  title: '',
  description: 'Your changes have been failed!',
};
export const CREATE_REPORT_SUCCEEDED_MESSAGE = {
  title: 'New report',
  description: 'You have successfully created the new report',
};
export const CREATE_REPORT_FAILED_MESSAGE = {
  title: 'New report',
  description: 'Create new report failed!',
};
export const DUPLICATE_REPORT_SUCCEEDED_MESSAGE = {
  title: 'Duplicate report',
  description: 'You have successfully duplicated the report',
};
export const EDIT_REPORT_SUCCEEDED_MESSAGE = {
  title: 'Edit report',
  description: 'You have successfully edited the report',
};
export const EDIT_REPORT_FAILED_MESSAGE = {
  title: 'Edit report',
  description: 'Edit report failed!',
};
export const DELETE_REPORT_SUCCEEDED_MESSAGE = {
  title: 'Delete report',
  description: 'You have successfully deleted the report',
};
export const DELETE_REPORT_FAILED_MESSAGE = {
  title: 'Delete report',
  description: 'Delete report failed!',
};
export const SEND_FOR_REVIEW_SUCCEEDED_MESSAGE = {
  title: 'Send for review',
  description: 'You have successfully sent for review',
};
export const SEND_FOR_REVIEW_FAILED_MESSAGE = {
  title: 'Send for review',
  description: 'Send for review failed!',
};
export const SEND_FOR_APPROVE_SUCCEEDED_MESSAGE = {
  title: 'Send for approve',
  description: 'You have successfully sent for review',
};
export const SEND_FOR_APPROVE_FAILED_MESSAGE = {
  title: 'Send for approve',
  description: 'Send for approve failed!',
};
export const APPROVE_REPORT_SUCCEEDED_MESSAGE = {
  title: 'You have approved the report',
  description: 'A notification of approval has been sent to the reporter',
};
export const APPROVE_REPORT_FAILED_MESSAGE = {
  title: 'Approve report',
  description: 'Approve report failed!',
};
export const APPROVE_KPI_CATEGORY_FAILED_MESSAGE = {
  title: 'Approve report',
  description: 'Approve the KPI category failed!',
};
export const REJECT_KPI_CATEGORY_FAILED_MESSAGE = {
  title: 'Reject report',
  description: 'Reject the KPI category failed!',
};
export const REPUBLISH_REPORT_SUCCEEDED_MESSAGE = {
  title: 'Republish report',
  description: 'You have successfully republished report',
};
export const REPUBLISH_REPORT_FAILED_MESSAGE = {
  title: 'Republish report',
  description: 'Republish report failed!',
};
export const REJECT_REPORT_SUCCEEDED_MESSAGE = {
  title: 'You have rejected the report',
  description:
    'A notification has been sent to the reporter, action will be taken based on your remark.',
};
export const REJECT_REPORT_FAILED_MESSAGE = {
  title: 'Reject report',
  description: 'Reject report failed!',
};
export const ADD_INSIGHT_SUCCEEDED_MESSAGE = {
  title: 'New insight',
  description: 'You have successfully created the new insight',
};
export const ADD_INSIGHT_FAILED_MESSAGE = {
  title: 'New insight',
  description: 'Create new insight failed!',
};
export const EDIT_INSIGHT_SUCCEEDED_MESSAGE = {
  title: 'Edit insight',
  description: 'You have successfully edited the insight',
};
export const EDIT_INSIGHT_FAILED_MESSAGE = {
  title: 'Edit insight',
  description: 'Edit insight failed!',
};
export const DELETE_INSIGHT_SUCCEEDED_MESSAGE = {
  title: 'Remove insight',
  description: 'You have successfully removed insight',
};
export const DELETE_INSIGHT_FAILED_MESSAGE = {
  title: 'Remove insight',
  description: 'Remove insight failed!',
};
export const ADD_KPI_CATEGORIES_SUCCEEDED_MESSAGE = {
  title: 'Add KPI categories',
  description: 'You have successfully add KPI categories',
};
export const ADD_KPI_CATEGORIES_FAILED_MESSAGE = {
  title: 'Add KPI categories',
  description: 'Add KPI categories failed!',
};
export const DELETE_KPI_CATEGORIES_SUCCEEDED_MESSAGE = {
  title: 'Remove KPI categories',
  description: 'You have successfully removed KPI categories',
};
export const DELETE_KPI_CATEGORIES_FAILED_MESSAGE = {
  title: 'Remove KPI categories',
  description: 'Remove KPI categories failed!',
};
export const MANAGE_ATTACHMENTS_SUCCEEDED_MESSAGE = {
  title: 'Add attachments',
  description: 'You have successfully added attachments',
};
export const MANAGE_ATTACHMENTS_FAILED_MESSAGE = {
  title: 'Add attachments',
  description: 'Add attachments failed!',
};
export const CREATE_NEW_FUNCTIONS_SUCCEEDED_MESSAGE = {
  title: 'New KPI Categories created',
  description: 'You have successfully created the new KPI categories',
};
export const CREATE_NEW_FUNCTIONS_FAILED_MESSAGE = {
  title: 'New KPI Categories failed!',
  description: 'Create new KPI categories failed!',
};
export const DELETE_FUNCTION_ATTACHMENT_SUCCEEDED_MESSAGE = {
  title: 'Delete attachment',
  description: 'You have successfully deleted attachment',
};
export const DELETE_FUNCTION_ATTACHMENT_FAILED_MESSAGE = {
  title: 'Delete attachment',
};
export const ADD_KPI_SUCCEEDED_MESSAGE = {
  title: 'Add KPI',
  description: 'You have successfully add KPI',
};
export const ADD_KPI_MESSAGE = {
  title: 'Add KPI',
  description: 'Add KPI failed!',
};
export const DELETE_KPI_SUCCEEDED_MESSAGE = {
  title: 'Delete KPI',
  description: 'You have successfully deleted KPI',
};
export const DELETE_KPI_FAILED_MESSAGE = {
  title: 'Delete KPI',
  description: 'Delete KPI failed!',
};
export const UPDATE_FORECAST_DATA_SUCCEEDED_MESSAGE = {
  title: 'Update Forecast Data',
  description: 'You have successfully save the data',
};
export const UPDATE_FORECAST_DATA_FAILED_MESSAGE = {
  title: 'Update Forecast Data',
  description: 'Save the data failed!',
};
export const ADD_PERFORMANCE_ANALYSIS_SUCCEEDED_MESSAGE = {
  title: '',
  description: 'You have successfully save the data',
};
export const ADD_PERFORMANCE_ANALYSIS_FAILED_MESSAGE = {
  title: '',
  description: 'Add Performance Analysis failed!',
};
export const DELETE_PERFORMANCE_ANALYSIS_SUCCEEDED_MESSAGE = {
  title: 'Delete Performance Analysis',
  description: 'You have successfully deleted Performance Analysis',
};
export const DELETE_PERFORMANCE_ANALYSIS_FAILED_MESSAGE = {
  title: 'Delete Performance Analysis',
  description: 'Delete Performance Analysis failed!',
};
export const DELETE_DATA_VISUALISATION_CHART_SUCCEEDED_MESSAGE = {
  title: 'Delete Data Visualisation',
  description: 'You have successfully deleted Data Visualisation',
};
export const DELETE_DATA_VISUALISATION_CHART_FAILED_MESSAGE = {
  title: 'Delete Data Visualisation',
  description: 'Delete Data Visualisation failed!',
};

export const SEND_FOR_REVIEW_BY_CATEGORY_SUCCEEDED_MESSAGE = categoryName => ({
  title: `${categoryName} sent for reviewer`,
  description:
    'A notification of review has been sent to the assigned reviewers',
});

export const SEND_FOR_REVIEW_BY_CATEGORY_FAILED_MESSAGE = categoryName => ({
  title: `${categoryName} sent for reviewer`,
  description:
    'A notification of review has been failed to the assigned reviewers',
});

export const APPROVE_KPI_CATEGORY_SUCCEEDED_MESSAGE = categoryName => ({
  title: `You have approved the KPI Category ${categoryName}`,
  description: 'A notification of approval has been sent to the reporter',
});

export const REJECT_KPI_CATEGORY_SUCCEEDED_MESSAGE = categoryName => ({
  title: `You have rejected the KPI Category ${categoryName}`,
  description:
    'A notification has been sent to the reporter, action will be taken based on your remark.',
});

export const SOMETHING_WENT_WRONG = 'Something went wrong!';
export const GET_TOTAL_UNREAD_MESSAGES_FAILURE = 'Get Total Messages Unread Failure';
